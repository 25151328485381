<template>
  <div v-if="!show" style="padding: 100px 20px 20px 20px;text-align: center;">
      <div>
        <van-image
            width="100"
            height="100"
            :src="imgUrl"
        />
      </div>
      <div style="font-size: 18px;color: #646566">
        {{text}}
      </div>
      <div style="padding-top:30px">
<!--        <van-button plain block type="danger" @click="goUserHome">关闭页面</van-button>-->
      </div>
      <div v-if="!result"  style="padding-top:30px">
        <van-button plain block type="info" @click="getUserAuthResult">{{$t('刷新结果')}}</van-button>
      </div>
  </div>
</template>

<script>
import {setToken} from "@/util/auth";
import {userAuthResult} from "@/api/user";
import {setImei, setSaasId} from "@/util/session-storage-utils";
import {Toast, Image as VanImage,Button} from "vant";
export default {
  name: "index",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  created() {
    setToken(this.$route.query._token)
    let imei = this.$route.query._imei;
    setImei(imei)
    let saasId = this.$route.query._saasId;
    setSaasId(saasId)
    this.getUserAuthResult();
  },
  computed:{
    text(){
      return this.result?this.$t("认证成功"):this.$t("认证失败")
    },
    imgUrl(){
      return this.result?"http://cdn.youshusoft.com/static/image/auth_succ.png":"http://cdn.youshusoft.com/static/image/auth_fail.png"
    }
  },
  data(){
    return {
      result:null,
      show:false
    }

  },
  methods:{
    getUserAuthResult(){
      this.show = true;
      Toast.loading({message:this.$t("加载中"),duration:0,position:"top"})
      userAuthResult(this.$route.query.biztoken).then(res => {
        this.result = res.data;
        // Toast.loading({message:"加载中...",duration:1,position:"top"})
        Toast.clear()
      }).finally( () => {

        this.show = false;
      })
    },
    goUserHome(){
      window.wx.miniProgram.navigateTo({url: '/pages/user/auth/main'})

    }
  }
}
</script>

<style scoped>

</style>
